import "primeicons/primeicons.css";
import NavBar from "../components/NavBar";
import ReactGA from "react-ga4";
ReactGA.initialize("G-HE4NNTJ0VR");
ReactGA.send({ hitType: "pageview", page: "/programme", title: "Programme" });

export default function Programme() {
  return (
    <div className="flex flex-col place-content-center justify-center pb-40">
      <NavBar />
      <div className="">
        <img src="m3.webp" className="w-full h-full" />
        <img src="m4.webp" className="w-full h-full" />
      </div>
    </div>
  );
}
