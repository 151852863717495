
import './App.css';
import Landing from './screens/Landing';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Programme from "./screens/Programme";
import Contact from './screens/Contact';
import Itinerary from './screens/Itinerary';
import Menu from './screens/Menu';


function App() {
  return (
      <>
          <Router>
              <Routes>
                  <Route
                      exact
                      path="/"
                      element={<Landing />}
                  />
                   <Route
                      exact
                      path="/programme"
                      element={<Programme />}
                  />
                   <Route
                      exact
                      path="/contact"
                      element={<Contact />}
                  />
                    <Route
                      exact
                      path="/itinerary"
                      element={<Itinerary />}
                  />
                   <Route
                      exact
                      path="/menu"
                      element={<Menu />}
                  />
              </Routes>
          </Router>
      </>
  );
}

export default App;