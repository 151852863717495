import axios from "axios";
import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { Dialog, Transition } from "@headlessui/react";
import ProgressBar from "@ramonak/react-progress-bar";
import ReactGA from "react-ga4";

ReactGA.initialize("G-HE4NNTJ0VR");
ReactGA.send({ hitType: "pageview", page: "/", title: "Landing" });
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export default function Landing() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");
  let [completed, setCompleted] = useState(0);
  let [error, setError] = useState();

  // function cameraBT() {
  //   openCamera(true);
  //   // window.alert("All the images will be stored in MSC 2024 Google Drive");
  //   document.getElementById("image").click();
  // }
  function cameraBT() {
    ReactGA.event({
      category: "camera",
      action: "open",
      label: "open_camera", // optional
    });

    // openCamera(true);
    document.getElementById("image").click();

    setTimeout(function () {
      // closeCameraModal(true);
    }, 2000);
  }

  function fileBT() {
    ReactGA.event({
      category: "file_input",
      action: "open",
      label: "open_file_input", // optional
    });
    document.getElementById("image_file_upload").click();
  }

  let [isOpen, setIsOpen] = useState(false);
  let [isNote, setNote] = useState(false);
  let [isCamera, setIsCamera] = useState(false);

  function note() {
    setNote(true);
  }

  function openCamera() {
    setIsCamera(true);
  }
  function closeCameraModal() {
    setIsCamera(false);
    setNote(false);
  }
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  async function sendImageCamera(e) {
    setIsOpen(true);

    // URL.revokeObjectURL(e);
    // document.body.removeChild(a);
    let data = new FormData();
    data.append("image", e);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://mizoramsportsconclave.com/api/uploadImage",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setIsOpen(false);
        setIsCamera(false);

        const a = document.createElement("a");
        a.href = URL.createObjectURL(e);
        a.download =
          "MSC 2024" +
          " - " +
          new Date().getTime() +
          e.name.replace("image", "");
        document.body.appendChild(a);
        a.click();
      })
      .catch((error) => {
        console.log(error);

        setError(error);
        setIsOpen(false);
        setIsCamera(false);
        window.alert("Some error occured");

        const a = document.createElement("a");
        a.href = URL.createObjectURL(e);
        a.download =
          "MSC 2024" +
          " - " +
          new Date().getTime() +
          e.name.replace("image", "");
        document.body.appendChild(a);
        a.click();
      });
  }
  async function sendImage(e) {
    // if(!e.files.length)
    //   {
    //     return 0
    //   }
    setIsOpen(true);
    for (let i = 0; i < e.files.length; i++) {
      ReactGA.event({
        category: "file_upload",
        action: "upload",
        label: "file_upload", // optional
      });
      let data = new FormData();
      data.append("image", e.files[i]);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://mizoramsportsconclave.com/api/uploadImage",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };

      await axios
        .request(config)
        .then(async (response) => {
          console.log(JSON.stringify(response.data));
          setCompleted(Math.floor((i / (e.files.length - 1)) * 100));
          if (i === e.files.length - 1) {
            setTimeout(() => {
              setIsOpen(false);
              setIsCamera(false);
              setCompleted(0);
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsOpen(false);
          setIsCamera(false);
          window.alert("Some error occured");
          setCompleted(0);
        });
    }
  }
  return (
    <div className="">
      <Transition appear show={isNote} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeCameraModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full text-md font-semibold font-poppins text-center max-w-md transform overflow-hidden rounded-2xl bg-white p-6  align-middle shadow-xl transition-all">
                  <div className=" text-md font-semibold font-poppins">
                    Application Closed!
                  </div>
                  <div className="mt-1 font-poppins text-xs font-light">
                    Thank you for your active participation and insightful
                    contributions. We hope you enjoyed the event.
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isCamera} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeCameraModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full text-md font-semibold font-poppins text-center max-w-md transform overflow-hidden rounded-2xl bg-white p-6  align-middle shadow-xl transition-all">
                  Welcome to MSC 2024!
                  {/* <p className=" font-poppins text-sm font-light">
                    We are thrilled to have you with us for this exciting event.
                  </p> */}
                  <div className="mt-1 font-poppins text-xs font-light">
                    We invite you to enrich the visual story of our conclave by
                    capturing and sharing images, which will then be uploaded to
                    the MSC 2024 Google Drive for collective viewing.
                  </div>
                  <div className="mt-1 font-poppins text-xs">
                    Thank you for helping us create lasting memories.
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full text-center  max-w-md transform overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all">
                  Uploading to MSC 2024 Gallery
                  <div className="mt-2">
                    <ClipLoader
                      color={color}
                      loading={loading}
                      cssOverride={override}
                      size={40}
                      className="mt-5"
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                    <ProgressBar className="mt-5" completed={completed} />
                  </div>
                  <div className="flex justify-center place-items-center">
                    <button
                      onClick={closeModal}
                      className=" bg-[#bc4548] w-20 h-10 text-white text-sm rounded-sm mt-3 flex justify-center place-items-center"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div className=" flex flex-col place-items-center   w-full">
        <div className="mt-10 mb-5 font-engagement text-7xl text-gray-800">
          Chibai
        </div>
        <img src="logo.webp" className=" w-40 h-20" />
        <input
          onChange={(e) => sendImageCamera(e.target.files[0])}
          type="file"
          accept="image/*"
          capture="environment"
          id="image"
          className="hidden"
        />
        <div className="mt-10 text-md flex flex-col justify-center place-items-center mb-10">
          <button
            onClick={openCamera}
            className=" flex flex-row justify-center place-items-center"
          >
            <div className=" flex flex-row justify-center font-poppins text-xl">
              Capture The Event
            </div>
            <i
              className="pi pi-info-circle text-[#0094d3] pl-2"
              style={{ fontSize: "1rem" }}
            />
          </button>

          <div className=" font-poppins mt-3 text-bold text-xl text-[#e72e34] animate-bounce">
            CLICK HERE
          </div>

          <i
            className="pi pi-arrow-down text-[#e72e34]"
            style={{ fontSize: "1rem" }}
          />
          <div className=" flex flex-col justify-center place-items-center">
            <button onClick={note}>
              <img src="camera.webp" className=" h-14 w-20" />
            </button>
            <input
              onChange={(e) => sendImage(e.target)}
              type="file"
              multiple
              accept="image/*"
              id="image_file_upload"
              className="hidden "
            />
          </div>
          <div>
            <div className="font-bold border-t-2 w-52 mt-3 relative">
              <div className="px-1 bg-white absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2">
                OR
              </div>
              <div className="mt-3 flex justify-center place-items-center">
                <button
                  onClick={note}
                  className="w-40 h-16 bg-[#0094d3] rounded-md text-white mt-2 flex flex-row justify-center place-items-center"
                >
                  <div className="flex justify-center items-center pr-2">
                    <div className="text-center">Upload </div>
                  </div>
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className=" size-5 mx-auto"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSe8bWwIHfbypGwS4CTwswaLP8sNTkN8ZB3bolfoY2Qc3s7h7Q/viewform"
          className="w-full flex justify-center place-content-center"
        >
          <button className="mt-2 text-sm w-2/3 h-10 text-white bg-[#bc4548] font-poppins rounded-sm">
            Feedback
          </button>
        </a>
        <Link
          to="/programme"
          className="w-full  flex justify-center place-items-center"
        >
          <button className="mt-2 text-sm w-2/3 h-10 text-white font-poppins bg-[#f8b521] rounded-sm">
            Programme
          </button>
        </Link>
        <a
          href="https://drive.google.com/drive/u/6/folders/1Bo66nz1duN2jvIXYNkB5JR8nbWalReaT"
          className="w-full flex justify-center place-content-center"
        >
          <button className="mt-2 text-sm w-2/3 h-10 text-white bg-[#27793c] font-poppins rounded-sm">
            Gallery
          </button>
        </a>
        <Link
          to="/itinerary"
          className="w-full  flex justify-center place-items-center"
        >
          <button className="mt-2 text-sm w-2/3 h-10 text-white font-poppins bg-[#0094d3] rounded-sm">
            Itinerary
          </button>
        </Link>
        <Link
          to="/contact"
          className="w-full flex justify-center place-items-center"
        >
          <button className="mt-2 text-sm w-2/3 h-10 text-white font-poppins bg-[#bc4548] rounded-sm">
            Contact Us
          </button>
        </Link>
        <a
          href="https://mizoramsportsconclave.com/"
          className="w-full flex justify-center place-content-center mb-20"
        >
          <button className="mt-2 text-sm w-2/3 h-10 text-white bg-[#29231a] font-poppins rounded-sm">
            mizoramsportsconclave.com
          </button>
        </a>
      </div>
      <div className="mb-20"></div>
    </div>
  );
}
