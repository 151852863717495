import NavBar from "../components/NavBar";
import ReactGA from "react-ga4";
ReactGA.initialize("G-HE4NNTJ0VR");
ReactGA.send({ hitType: "pageview", page: "/itinerary", title: "Itinerary" });

export default function Itinerary() {
  return (
    <div className="flex flex-col place-content-center justify-center pb-40">
      <NavBar />
      <div className="mt-2">
        <img src="i1.webp" className="w-full h-full" />
        <img src="i2.webp" className="w-full h-full" />
        <img src="i3.webp" className="w-full h-full" />
        <img src="i4.webp" className="w-full h-full" />
        <img src="i5.webp" className="w-full h-full" />
        <img src="i6.webp" className="w-full h-full" />
        <img src="i7.webp" className="w-full h-full" />
      </div>
    </div>
  );
}
