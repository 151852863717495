import "primeicons/primeicons.css";
import NavBar from "../components/NavBar";
import ReactGA from "react-ga4";
ReactGA.initialize("G-HE4NNTJ0VR");
ReactGA.send({ hitType: "pageview", page: "/Contact", title: "Contact" });

export default function Contact() {
  return (
    <div className="h-screen">
      <NavBar />
      <div className="mt-10 mb-10 text-center w-full font-engagement text-5xl">
        Contact Us
      </div>
      <div className=" flex flex-col place-items-center justify-center mt-5">
        <div className=" text-center font-bold">Security and Medical</div>
        <div className=" text-center">B Rina</div>
        <div className=" flex flex-row mt-1 justify-between place-content-center">
          <a
            href="tel://+91 8119967761"
            className="text-white font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#0094d3]">
              <i className="pi pi-phone" style={{ fontSize: "1rem" }} />
            </button>
          </a>
          <a
            href="https://wa.me/+918119967761"
            className="text-white ml-3 font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#27793c]">
              <i className="pi pi-whatsapp" style={{ fontSize: "1rem" }} />
            </button>
          </a>
        </div>
      </div>
      <div className=" flex flex-col place-items-center justify-center mt-5">
        <div className=" text-center font-bold">Hospitality and Logistics</div>
        <div className=" text-center">Moses</div>
        <div className=" flex flex-row mt-1 justify-between place-content-center">
          <a
            href="tel://+91 9560481447"
            className="text-white font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#0094d3]">
              <i className="pi pi-phone" style={{ fontSize: "1rem" }} />
            </button>
          </a>
          <a
            href="https://wa.me/+919560481447"
            className="text-white ml-3 font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#27793c]">
              <i className="pi pi-whatsapp" style={{ fontSize: "1rem" }} />
            </button>
          </a>
        </div>
        <div className=" text-center">Dennis</div>
        <div className=" flex flex-row mt-1 justify-between place-content-center">
          <a
            href="tel://+91 6033088677"
            className="text-white font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#0094d3]">
              <i className="pi pi-phone" style={{ fontSize: "1rem" }} />
            </button>
          </a>
          <a
            href="https://wa.me/+916033088677"
            className="text-white ml-3 font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#27793c]">
              <i className="pi pi-whatsapp" style={{ fontSize: "1rem" }} />
            </button>
          </a>
        </div>
      </div>
      <div className=" flex flex-col place-items-center justify-center mt-5">
        <div className=" text-center font-bold">
          Registration and Communication
        </div>
        <div className=" text-center">Joseph</div>
        <div className=" flex flex-row mt-1 justify-between place-content-center">
          <a
            href="tel://+91 9643128553"
            className="text-white font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#0094d3]">
              <i className="pi pi-phone" style={{ fontSize: "1rem" }} />
            </button>
          </a>
          <a
            href="https://wa.me/+919643128553"
            className="text-white ml-3 font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#27793c]">
              <i className="pi pi-whatsapp" style={{ fontSize: "1rem" }} />
            </button>
          </a>
        </div>
      </div>
      <div className=" flex flex-col place-items-center justify-center mt-5">
        <div className=" text-center font-bold">Event Management</div>
        <div className=" text-center">Zira</div>
        <div className=" flex flex-row mt-1 justify-between place-content-center">
          <a
            href="tel://+91 8729986889"
            className="text-white font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#0094d3]">
              <i className="pi pi-phone" style={{ fontSize: "1rem" }} />
            </button>
          </a>
          <a
            href="https://wa.me/+918729986889"
            className="text-white ml-3 font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#27793c]">
              <i className="pi pi-whatsapp" style={{ fontSize: "1rem" }} />
            </button>
          </a>
        </div>
      </div>
      <div className=" flex flex-col place-items-center justify-center mt-5">
        <div className=" text-center font-bold">
          Documentation and Publicity
        </div>
        <div className=" text-center">Scarlett</div>
        <div className=" flex flex-row mt-1 justify-between place-content-center">
          <a
            href="tel://+91 7303533364"
            className="text-white font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#0094d3]">
              <i className="pi pi-phone" style={{ fontSize: "1rem" }} />
            </button>
          </a>
          <a
            href="https://wa.me/+917303533364"
            className="text-white ml-3 font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#27793c]">
              <i className="pi pi-whatsapp" style={{ fontSize: "1rem" }} />
            </button>
          </a>
        </div>
      </div>
      <div className=" flex flex-col place-items-center justify-center mt-5">
        <div className=" text-center font-bold">Catering and Food</div>
        <div className=" text-center">Rokimi</div>
        <div className=" flex flex-row mt-1 justify-between place-content-center">
          <a
            href="tel://+91 7005420028"
            className="text-white font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#0094d3]">
              <i className="pi pi-phone" style={{ fontSize: "1rem" }} />
            </button>
          </a>
          <a
            href="https://wa.me/+917005420028"
            className="text-white ml-3 font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#27793c]">
              <i className="pi pi-whatsapp" style={{ fontSize: "1rem" }} />
            </button>
          </a>
        </div>
      </div>
      <div className=" flex flex-col place-items-center justify-center mt-5 pb-40">
        <div className=" text-center font-bold">Production and Technology</div>
        <div className=" text-center">Hminga</div>
        <div className=" flex flex-row mt-1 justify-between place-content-center">
          <a
            href="tel://+91 8415098997"
            className="text-white font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#0094d3]">
              <i className="pi pi-phone" style={{ fontSize: "1rem" }} />
            </button>
          </a>
          <a
            href="https://wa.me/+918415098997"
            className="text-white ml-3 font-semibold justify-center flex place-items-center"
          >
            <button className=" w-20 h-8 rounded-md bg-[#27793c]">
              <i className="pi pi-whatsapp" style={{ fontSize: "1rem" }} />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
