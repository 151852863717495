export default function NavBar() {
  return (
    <header class="top-0 z-50 h-12 sm:h-20 sticky bg-white">
      <nav>
        <div className="flex sticky flex-row justify-between ">
          <a
            href="/"
            className=" flex place-items-center my-auto justify-center text-[#0094d3]"
          >
            <i
              className="pi pi-angle-left mt-2 ml-2"
              style={{ fontSize: "1rem" }}
            />{" "}
            <div className="text-[#0094d3] text-xs my-auto mt-2 ml-1">Back</div>
          </a>
          <a href="/" className="text-xs mr-5">
            <img src="logo.webp" className="h-10 w-14 sm:h-16 sm:w-auto" />
          </a>
        </div>
      </nav>
    </header>
  );
}
